<template lang="">
    <div class="result">
        <a-row :gutter="[16, 16]" v-if="!isLoading">
            <a-col :xs="0" :sm="0" :lg="0" :xl="6" class="box-left">
                <QuestionMenu :type="'RESULT'" :isSubmitTest="false" />
            </a-col>
            <a-col :xs="24" :sm="24" :lg="24" :xl="18">
                <div class="box-right">
                    <div class="box-score">
                        <Time :start="false" :time="recordObject.seconds / 60" v-if="testObject.time_limit" />
                        <h3>
                            {{ $t(recordObject.is_pass ? 'result_page.congratulations' : 'result_page.thank_you') }}
                        </h3>
                        <vue-circle
                            :progress="Number(recordObject.user_score)"
                            :size="150"
                            empty-fill="rgba(0, 0, 0, 0.1)"
                            :fill="{ color: recordObject.is_pass ? '#19ce7a' : '#ff5252' }"
                            :start-angle="0"
                            insert-mode="append"
                        >
                            <p class="result">
                                {{ recordObject.is_pass ? $t('course_detail.pass') : $t('course_detail.fail') }}
                            </p>
                            <p :class="recordObject.is_pass ? 'score' : 'score_fail'">
                                {{ Math.round(recordObject.user_score) }} / 100
                            </p>
                        </vue-circle>

                        <h5 class="mt-3">{{ $t('course_detail.point_pass', { point: testObject.pass_score }) }}</h5>

                        <div class="btn-back">
                            <button @click="onGoBack">
                                <v-icon class="fa-solid fa-chevron-left"></v-icon>
                                <span>{{ $t('go_back') }}</span>
                            </button>
                        </div>
                    </div>
                    <QuestionList :type="'RESULT'" />
                    <div class="btn-back">
                        <button @click="onGoBack">
                            <v-icon class="fa-solid fa-chevron-left"></v-icon>
                            <span>{{ $t('go_back') }}</span>
                        </button>
                    </div>
                </div>
            </a-col>
        </a-row>
        <Loading v-if="isLoading" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import VueCircle from 'vue2-circle-progress';

import Time from '../Test/Time.vue';
import Loading from '../Loading/Loading.vue';
import QuestionMenu from '../Questions/QuestionMenu.vue';
import QuestionList from '../Questions/QuestionList.vue';

import { mixinToasts } from '../../../mixins';

export default {
    mixins: [mixinToasts],
    props: {
        isCMS: { type: Boolean, required: false },
    },
    components: {
        VueCircle,
        Time,
        Loading,
        QuestionMenu,
        QuestionList,
    },
    data() {
        return {
            isLoading: false,
        };
    },
    computed: {
        ...mapState('testWeb', ['testObject']),
        ...mapState('recordWeb', ['recordObject']),
    },
    async mounted() {
        // await this.resetState();
        await this.getTestAction();
        await this.getRecordAction();
    },
    methods: {
        ...mapActions('testWeb', ['getTest']),
        ...mapActions('recordWeb', ['getRecord', 'getRecordCMS', 'getRecordUser']),

        async getTestAction() {
            this.isLoading = true;
            await this.getTest({
                id: this.$route.query.test_id,
                onSuccess: (isSuccess, data) => {
                    if (!isSuccess) {
                        this.showError('error', data);
                    }
                },
            });
            this.isLoading = false;
        },

        async getRecordAction() {
            this.isLoading = true;
            if (this.isCMS) {
                await this.getRecordCMS({
                    test_id: this.$route.query.test_id,
                    test_attend_id: this.$route.query.test_attend_id,
                    onSuccess: (isSuccess, data) => {
                        if (!isSuccess) {
                            this.showError('error', data);
                        }
                    },
                });
            } else {
                if (this.$route.query.test_attend_id) {
                    await this.getRecordUser({
                        test_id: this.$route.query.test_id,
                        test_attend_id: this.$route.query.test_attend_id,
                        onSuccess: (isSuccess, data) => {
                            if (!isSuccess) {
                                this.showError('error', data);
                            }
                        },
                    });
                } else {
                    await this.getRecord({
                        params: {
                            id: this.$route.params.id,
                            test_id: this.$route.query.test_id,
                            user_id: this.$store.getters.getUserInfo.id,
                        },
                        onSuccess: (isSuccess, data) => {
                            if (!isSuccess) {
                                this.showError('error', data);
                            }
                        },
                    });
                }
            }
            this.isLoading = false;
        },

        onGoBack() {
            if (this.$route.name !== 'WEB' || this.$route.query.test_attend_id) {
                this.$router.back();
            } else {
                this.$router.go(-2);
            }
        },
    },
};
</script>

<style lang="scss">
.result {
    & .btn-back {
        position: sticky;
        position: -webkit-sticky;
        top: 100px;
        & button {
            display: flex;
            align-items: center;
            width: max-content;
            padding: 6.5px 33px;
            border-radius: 50px;
            margin: 30px auto 0;
            background: #007aff;
            color: #fff;
            font-size: 18px;
            font-weight: bold;
            & i {
                margin-right: 10px;
                color: #fff;
            }
        }
    }
}

.box-left {
    position: sticky;
    position: -webkit-sticky;
    top: 100px;
}

.box-score {
    margin-bottom: 10px;
    padding: 40px 10px 25px;
    background-color: #fff;
    border-radius: 15px;
    text-align: center;
    & h3 {
        margin-bottom: 30px;
        font-weight: 600;
        font-size: 32px;
    }
    & .percent-text {
        display: none;
    }

    & .result {
        font-size: 30px;
        font-weight: 700;
        margin: 0;
        padding: 0;
    }

    & .score {
        margin: 0;
        font-size: 20px;
        font-weight: 700;
        color: #19ce7a;
    }

    & .score_fail {
        margin: 0;
        font-size: 20px;
        font-weight: 700;
        color: #ff5252;
    }
}
</style>
